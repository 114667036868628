/* 印尼语  Indonesian */
export default {
     '一起社交赚钱': 'Mari Social And Earn',
     '重连...': 'Menghubungkan kembali...',
     '收件箱': 'Kotak Surat',
     '聊天': 'Obrolan',
     '消息/聊天': 'Pesan',
     '消息 - 未读': 'Pesan - Belum Dibaca',
     '没有更多了': 'Tidak Ada Lagi',
     '暂无昵称': 'Belum Ada Nama Panggilan',
     '排行': 'Peringkat',
     '在此输入...': 'Ketik Disini...',
     '连接中...': 'Menghubungkan...',
     '发送消息不能为空': 'Pesan Harus Terisi',
     '连接钱包': 'Menghubungkan Dompet',
     '选择要连接的钱包': 'Pilih Dompet Untuk Dihubungkan',
     '钱包': 'Dompet',
     '好友': 'Teman',
     '朋友': 'Teman',
     '加载中...': 'Teman...',
     '添加好友': 'Memuat',
     '请输入搜索关键词': 'Tambah teman',
     '推荐玩家': 'Harap Masukkan Kata Kunci Pencarian',
     '刷新': 'Segarkan',
     '请求': 'Minta',
     '好友请求': 'Permintaan Pertemanan',
     '积分': 'Poin',
     'NFT收藏': 'Koleksi NFT',
     '探索距离': 'Jarak',
     '步数': 'Langkah',
     '心率': 'Detak Jantung',
     '心率差': 'perbedaan detak jantung',
     '卡路里': 'Kalori',
     '时长': 'Durasi',
     '耐用度': 'Daya tahan',
     '开始运动': 'Mulai',
     '结束运动': 'Akhiri Latihan',
     '恢复耐久': 'Pulihkan Daya Tahan',
     '日期预设': 'Tanggal Preset',
     '月': 'Bulan',
     '日': 'Hari',
     '户外跑步总记录': 'Laporan Lari Luar Ruangan',
     '查看活动报告': 'Lihat Laporan Aktivitas',
     '交易市场': 'Pasar',
     '社交挖矿': 'Social',
     '选择年月': 'Tentukan Tahun Dan Bulan',
     '修复中...': 'Memperbaiki...',
     '修复成功': 'Berhasil Diperbaiki',
     '修复失败': 'Gagal Diperbaiki',
     '市场': 'Pasar',
     '探索': 'Temukan',
     '热门': 'Populer',
     '拍卖': 'Lelang',
     '总共': 'Total',
     '暂无': 'Kosong',
     '购买': 'Beli',
     '授权': 'Izinkan',
     '余额不足': 'Saldo tidak mencukupi',
     '购买中...': 'Pembelian...',
     '购买成功': 'Pembelian Berhasil',
     '购买失败': 'Pembelian Gagal',
     '授权中...': 'Otorisasi...',
     '授权成功': 'Otorisasi Berhasil',
     '授权失败': 'Otorisasi Gagal',
     '我的NFT收藏': 'NFT saya',
     'NFT角色信息': 'Info NFT',
     '取消挂卖': 'Batal Daftar',
     '挂卖': 'Jual',
     '升级中...': 'Naik Level Sedang Berlangsung...',
     '升级': 'Naik Level',
     '修复耐久性': 'Pulihkan Daya Tahan',
     '信息': 'Pesan',
     '成长': 'Tingkatkan',
     '最高等级': 'Level Maksimum',
     '请输入挂卖价格': 'Harap Masukkan Harga Jual',
     '确定': 'Konfirmasi',
     '取消': 'Batal',
     '价格': 'Harga',
     '请输入价格': 'Harap Masukkan Harga',
     '取消中...': 'Pembatalan Sedang Berlangsung...',
     '取消成功': 'Pembatalan Berhasil',
     '取消失败': 'Pembatalan Gagal',
     '价格需要大于0': 'Harga Harus Lebih Besar Dari 0',
     '挂卖中...': 'Daftar Sedang Berlangsung...',
     '挂卖成功': 'Berhasil Terdaftar',
     '挂卖失败': 'Gagal Terdaftar ',
     '这张卡片不需要修复': 'NFT Ini Tidak Butuh Diperbaiki',
     '修复成功,等待交易查询中': 'Perbaikan Berhasil, Menunggu Transaksi',
     '升级失败': 'Peningkatan Gagal',
     '我的简历': 'Profil saya',
     '地址': 'Alamat',
     '我的钱包余额': 'Saldo Dompet Saya',
     '请输入口令': 'Harap Masukkan Kata Sandi',
     '当地语言': 'Bahasa Daerah',
     '评分列表': 'Daftar Peringkat',
     '购买评分': 'Peringkat Pembelian',
     '密码不能为空': 'Kata Sandi Harus Terisi',
     '复制成功': 'Disalin',
     '不支持复制': 'Tidak Dapat Disalin',
     '购买成功,等待交易查询中': 'Pembelian Berhasil, Menunggu Transaksi Untuk Dikonfirmasi',
     '主菜单': 'Menu utama',
     '首页': 'Halaman Utama',
     '任务': 'Misi',
     '排行榜': 'Peringkat',
     '开启运动模式': 'Move And Earn',
     '开启社交模式': 'Social And Earn',
     '聊天室': 'Room Sosial',
     '个人简介': 'Profil saya',
     '报告': 'Laporan',
     '时间': 'Waktu',
     '前三名玩家': '3 Pemain Terbaik',
     '暂无排名': 'Belum Ada Peringkat',
     '玩家排行': 'Peringkat Pemain',
     '选择年月日': 'Tentukan Tahun Bulan Hari',
     '运动': 'Latihan',
     '社交': 'Sosial',
     '总分': 'Poin Total',
     '持续': 'Lanjutkan',
     '玩家': 'Pemain',
     '等级': 'Level',
     '成本': 'Biaya',
     '开始时间': 'Waktu Mulai',
     '至': 'Sampai',
     '结束时间': 'Waktu Berakhir ',
     '收益': 'Penghasilan',
     '我的收益报告': 'Laporan Laba Saya',
     '奖励': 'Hadiah',
     '我的奖励报告': 'Laporan Hadiah Saya',
     '我的任务报告': 'Laporan Misi Saya',
     '我的NFT报告': 'Laporan NFT saya',
     '开始时间要小于结束时间': 'Waktu Mulai Harus Kurang Dari Waktu Berakhir',
     '结束时间要大于开始时间': 'Waktu Berakhir Harus Lebih Besar dari Waktu Mulai',
     '社交聊天': 'Obrolan Sosial',
     '聊天室名称': 'Nama Room Sosial',
     '人数': 'Jumlah Orang',
     '创建': 'Buat',
     '房间名不能为空': 'Nama Room Harus Terisi',
     '聊天室邀请': 'Undangan',
     '邀请': 'Undang',
     '暂无名称': 'Belum Ada Nama',
     '距离': 'Jarak',
     '准备': 'Siap',
     '邀请朋友': 'Undang teman',
     '米': 'Meter',
     '暂无房间id': 'Tidak ada ID Room',
     '我的聊天室': 'Room Sosial Saya',
     '购买钥匙': 'Beli Kunci',
     '准备中': 'Menyiapkan',
     '进行中': 'Sedang Berlangsung',
     '待评价': 'Menunggu Peringkat',
     '待结算': 'Menunggu Hadiah',
     '已结束': 'Selesai',
     '暂未创建或加入聊天室': 'Belum Ada Room Sosial yang Dibuat Atau Bergabung',
     '推荐聊天室': 'Room Sosial yang Disarankan',
     '头像': 'Foto Profil',
     '这个聊天室没有名称': 'Room Sosial Ini Tidak Memiliki Nama',
     '申请': 'Terapkan',
     '已加入聊天室': 'Sudah Bergabung dengan Room Sosial',
     '等待其他玩家评价': 'Menunggu Pemain Untuk Menilai',
     '您还没有创建房间': 'Anda Belum Membuat Room',
     '开始挖矿': 'Mulai',
     '退出房间': 'Keluar dari Room',
     '复制链接': 'Salin Tautan Undangan',
     '发送邀请': 'Kirim Undangan',
     '提示': 'Pengingat',
     '请确认是否退出聊天室': 'Mohon Konfirmasi Untuk Keluar dari Room Sosial',
     '请输入邀请链接': 'Harap Masukkan Tautan Undangan',
     '加入聊天室': 'Gabung',
     '在挖矿中': 'Social And Earn Sedang Diproses',
     '定时进程': 'Hitung Mundur',
     '完成挖矿': 'Selesaikan',
     '停止聊天室': 'Hentikan Room Sosial',
     '在线玩家': 'Pemain Online',
     '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益': 'Mohon Konfirmasi Untuk Menghentikan Room Sosial, Tindakan Ini Akan Meninggalkan Room Sosial Dan Tidak Akan Memperoleh Hadiah.',
     '社交聊天室': 'Room Sosial',
     '创建聊天室': 'Buat Room',
     '邀请链接': 'Tautan Undangan',
     '有新的请求，请在请求弹窗中查看': 'Ada Permintaan Baru, Silahkan Cek Di Popup Permintaan',
     '有新的邀请，请在邀请弹窗中查看': 'Ada Undangan Baru, Silahkan Cek Di Popup Undangan',
     '房主已拒绝': 'Pemilik Room Telah Menolak Permintaan Anda',
     '用户拒绝加入': 'Pemain Ditolak Bergabung',
     '挖矿收益': 'Hadiah Social And Earn ',
     '我的评分': 'Rating saya',
     '奖励分配': 'Alokasi Hadiah',
     '评分': 'Peringkat',
     '社交评分': 'Peringkat Sosial',
     '提交': 'Kirim',
     "暂无计划":"Belum Ada Rencana",
     "活动报告":"Laporan Aktivitas",
     "分钟":"Menit",
     "每日":"Setiap hari",
     "每月":"Setiap bulan",
     "获得":"Memperoleh",
     "总支出":"Total Pembelanjaan",
     "点 击 关 闭":"Klik Untuk Menutup",
     "朋友帮助请求":"Permintaan Bantuan Teman",
     "发送帮助":"Kirim Bantuan",
     "推荐任务":"Misi yang Disarankan",
     "描述":"Keterangan",
     "接受":"Terima",
     "待完成":"Tertunda",
     "特别活动":"Aktivitas Khusus",
     "特别":"Spesial",
     '此操作会将房间解散，并且不会退还钥匙': 'Tindakan Ini Akan Membubarkan Room Dan Kunci Tidak Akan Dikembalikan',
     '运动收益': 'Hadiah Olahraga',
     '社交收益': 'Penghargaan Sosial',
     '累计时长(分钟)': 'Waktu kumulatif (menit)',
     '实时运动': 'pengatur waktu',
     '领取': 'Mengeklaim',
     '交易成功后等级 + 1': 'Level +1 setelah transaksi berhasil',
     '信息更新中...': 'Informasi Sedang Diperbarui...',
     '连接钱包中...': 'Menghubungkan dompet...',
     '解散房间': 'Ruang Bubar',
     '未结算': 'gelisah',
     '已结算': 'Mapan',
     '日期选择': 'Pilihan tanggal',
     '未领取': 'Tidak diterima',
     '已领取': 'Diklaim',
     '已升级的nft数量': 'Kuantitas NFT yang ditingkatkan',
     '请选择修复次数':'Pilih jumlah perbaikan',
     '暂无nft可领取':'暂无nft可领取',
     '暂无团队收益可领取':'Saat ini tidak ada hadiah tim untuk diterima',
     '团队收益':'Penghargaan Tim',
     '当前修复分数': 'Daya Tahan Perbaikan Saat Ini',
     '请输入密码': 'Silakan Masukkan Kata Sandi',
     '设置密码': 'Tetapkan kata sandi',
     '密码': 'Kata sandi',
     '再次确认': 'konfirmasi sandi',
     '请再次输入密码': 'Silakan Masukkan Kata Sandi Lagi',
     '两次输入的密码不一致': 'Kata sandi tidak cocok',
     '未激活': 'Tidak aktif',
     '已激活': 'Diaktifkan',
     '成功': 'Berhasil',
     '失败': 'Gagal',
     '请确认是否将此用户请离房间': 'Harap konfirmasi jika melanjutkan untuk menghapus pengguna ini dari ruangan',
     '收藏奖励': 'Hadiah koleksi',
     '教程': 'Tutorial',
     '请选择类型': 'Silakan pilih tipe',
     '评分(人气)': 'Peringkat',
     '日榜': 'Hari',
     '月榜': 'Bulan',
     '总榜': 'Total',
     '升级进度': 'peningkatan kemajuan',
     '暂无收益可领取': 'Tidak ada pendapatan yang dapat diklaim sementara',
     '价格需要大于5000': 'Harga Harus Lebih Besar Dari 5000',
     '团队NFT升级奖励': 'Pendapatan Peningkatan NFT',
     '名称': 'Nama',
     '钱包地址': 'Alamat Dompet',
     '设置昵称': 'Tetapkan Nickname',
     '昵称': 'nama panggilan',
     '请输入昵称': 'Silakan masukkan nama panggilan',
     '昵称不能为空': 'Nama panggilan tidak dapat kosong',
     '请先选择头像': 'Silakan pilih avatar pertama',
     '设置个人信息': 'Tetapkan informasi pribadi',
     '数量': 'jumlah',
     '选择钥匙数量': 'Pilih nomor kunci',
     '房间人数需要大于2,才能正常退出': 'Jumlah orang di ruangan harus lebih besar dari 2 untuk keluar secara normal',
     '价格需要大于50000': 'Harganya harus lebih besar dari 50000',
     '是否确认绑定': 'Adakah anda yakin untuk mengikatkan?',
     '上级名称': 'Nama Atasan：',
     '上级邀请码': 'Kode Undangan Atasan：',
     '上级地址': 'Alamat Atasan：',
     '请通过新的邀请链接进入': 'Silakan masukkan melalui link undangan baru',
     '群组/聊天': '群组/聊天',
     '此卡已是最高等级': 'NFT ini sudah mencapai tingkat tertinggi',
     '领取中': 'Sedang mengklaim',
     '不可领取': 'Tidak dapat diklaim',
     '领取成功': 'Berhasil diklaim',
     '已卖出': 'Terjual',
     '已升级': 'Ditingkatkan',
     '请先选择房间': 'Silakan pilih kamar terlebih dahulu',
     '网络不好,请稍后再试': 'Koneksi jaringan buruk, silakan coba lagi nanti',
     '购买钥匙授权': 'Membeli izin kunci',
     '购买评分授权': 'Membeli izin penilaian',
     '修复耐久授权': 'Otorisasi perbaikan daya tahan',
     '升级授权': 'Otorisasi upgrade',
     '挂卖补偿奖励': 'Hadiah kompensasi penjualan',
     '进入聊天室': 'Masuk ke ruang obrolan sosial',
     '*请在1小时结束社交后进行评分': '*Harap beri penilaian setelah 1 jam interaksi sosial',
     '确认分享': 'Konfirmasi Berbagi',
     '账号未激活，是否分享邀请链接?': 'Akun belum diaktivasi, Anda ingin membagikan tautan undangan?',
     '绑定上级中,链上交易需要时间,请耐心等待几分钟再去操作...': 'Sedang menghubungkan ke atas, transaksi di rantai membutuhkan waktu, harap tunggu beberapa menit sebelum mengaktifkan...',
     '绑定成功': 'Berhasil terhubung',
     '绑定失败': 'Gagal terhubung',
     '链上已经绑定上级,交易确认中,请稍后再试': 'Atasan sudah terikat di rantai, konfirmasi transaksi sedang berlangsung, harap coba lagi nanti',
     '登录已过期,请重新登录': 'Sesi login telah berakhir, silakan login kembali.',
     '确认购买': 'Konfirmasi Pembelian',
     '购买钥匙当次所需数量为:': 'Jumlah yang dibutuhkan untuk membeli kunci:\n',
     '购买评分当次所需数量为:': 'Jumlah yang dibutuhkan untuk membeli penilaian:\n',
     '确认修复': 'Konfirmasi Perbaikan',
     '修复耐久当次所需数量为:': 'Jumlah yang dibutuhkan untuk memperbaiki daya tahan:\n',
     '确认升级': 'Konfirmasi Peningkatan',
     '升级NFT当次所需数量为:': 'Jumlah yang diperlukan untuk meningkatkan NFT adalah\n',
     "技术升级":"Perbaikan",

}