/* 中文 */
export default {
     '一起社交赚钱': '一 起 社 交 赚 钱',
     '重连...': '重连...',
     '收件箱': '收件箱',
     '聊天': '聊天',
     '消息/聊天': '消息/聊天',
     '消息 - 未读': '消息 - 未读',
     '没有更多了': '没有更多了',
     '暂无昵称': '暂无昵称',
     '排行': '排行',
     '在此输入...': '在此输入...',
     '连接中...': '连接中...',
     '发送消息不能为空': '发送消息不能为空',
     '连接钱包': '连接钱包',
     '选择要连接的钱包': '选择要连接的钱包',
     '钱包': '钱包',
     '好友': '好友',
     '朋友': '朋友',
     '加载中...': '加载中...',
     '添加好友': '添加好友',
     '请输入搜索关键词': '请输入搜索关键词',
     '推荐玩家': '推荐玩家',
     '刷新': '刷新',
     '请求': '请求',
     '好友请求': '好友请求',
     '积分': '积分',
     'NFT收藏': 'NFT收藏',
     '探索距离': '探索距离',
     '步数': '步数',
     '心率': '心率',
     '心率差': '心率差',
     '卡路里': '卡路里',
     '时长': '时长',
     '耐用度': '耐用度',
     '开始运动': '开始运动',
     '结束运动': '结束运动',
     '恢复耐久': '恢复耐久',
     '日期预设': '日期预设',
     '月': '月',
     '日': '日',
     '户外跑步总记录': '户外跑步总记录',
     '查看活动报告': '查看活动报告',
     '交易市场': '交易市场',
     '社交挖矿': '社交挖矿',
     '选择年月': '选择年月',
     '修复中...': '修复中...',
     '修复成功': '修复成功',
     '修复失败': '修复失败',
     '市场': '市场',
     '探索': '探索',
     '热门': '热门',
     '拍卖': '拍卖',
     '总共': '总共',
     '暂无': '暂无',
     '购买': '购买',
     '授权': '授权',
     '余额不足': '余额不足',
     '购买中...': '购买中...',
     '购买成功': '购买成功',
     '购买失败': '购买失败',
     '授权中...': '授权中...',
     '授权成功': '授权成功',
     '授权失败': '授权失败',
     '我的NFT收藏': '我的NFT收藏',
     'NFT角色信息': 'NFT角色信息',
     '取消挂卖': '取消挂卖',
     '挂卖': '挂卖',
     '升级中...': '升级中...',
     '升级': '升级',
     '修复耐久性': '修复耐久性',
     '信息': '信息',
     '成长': '成长',
     '最高等级': '最高等级',
     '请输入挂卖价格': '请输入挂卖价格',
     '确定': '确定',
     '取消': '取消',
     '价格': '价格',
     '请输入价格': '请输入价格',
     '取消中...': '取消中...',
     '取消成功': '取消成功',
     '取消失败': '取消失败',
     '价格需要大于0': '价格需要大于0',
     '挂卖中...': '挂卖中...',
     '挂卖成功': '挂卖成功',
     '挂卖失败': '挂卖失败',
     '这张卡片不需要修复': '这张卡片不需要修复',
     '修复成功,等待交易查询中': '修复成功,等待交易查询中',
     '升级失败': '升级失败',
     '我的简历': '我的简历',
     '地址': '地址',
     '我的钱包余额': '我的钱包余额',
     '请输入口令': '请输入口令',
     '当地语言': '当地语言',
     '评分列表': '评分列表',
     '购买评分': '购买评分',
     '密码不能为空': '密码不能为空',
     '复制成功': '复制成功',
     '不支持复制': '不支持复制',
     '购买成功,等待交易查询中': '购买成功,等待交易查询中',
     '主菜单': '主菜单',
     '首页': '首页',
     '任务': '任务',
     '排行榜': '排行榜',
     '开启运动模式': '开启运动模式',
     '开启社交模式': '开启社交模式',
     '聊天室': '聊天室',
     '个人简介': '个人简介',
     '报告': '报告',
     '时间': '时间',
     '前三名玩家': '前三名玩家',
     '暂无排名': '暂无排名',
     '玩家排行': '玩家排行',
     '选择年月日': '选择年月日',
     '运动': '运动',
     '社交': '社交',
     '总分': '总分',
     '持续': '持续',
     '玩家': '玩家',
     '等级': '等级',
     '成本': '成本',
     '开始时间': '开始时间',
     '至': '至',
     '结束时间': '结束时间',
     '收益': '收益',
     '我的收益报告': '我的收益报告',
     '奖励': '奖励',
     '我的奖励报告': '我的奖励报告',
     '我的任务报告': '我的任务报告',
     '我的NFT报告': '我的NFT报告',
     '开始时间要小于结束时间': '开始时间要小于结束时间',
     '结束时间要大于开始时间': '结束时间要大于开始时间',
     '社交聊天': '社交聊天',
     '聊天室名称': '聊天室名称',
     '人数': '人数',
     '创建': '创建',
     '房间名不能为空': '房间名不能为空',
     '聊天室邀请': '聊天室邀请',
     '邀请': '邀请',
     '暂无名称': '暂无名称',
     '距离': '距离',
     '准备': '准备',
     '邀请朋友': '邀请朋友',
     '米': '米',
     '暂无房间id': '暂无房间id',
     '我的聊天室': '我的聊天室',
     '购买钥匙': '购买钥匙',
     '准备中': '准备中',
     '进行中': '进行中',
     '待评价': '待评价',
     '待结算': '待结算',
     '已结束': '已结束',
     '暂未创建或加入聊天室': '暂未创建或加入聊天室',
     '推荐聊天室': '推荐聊天室',
     '头像': '头像',
     '这个聊天室没有名称': '这个聊天室没有名称',
     '申请': '申请',
     '已加入聊天室': '已加入聊天室',
     '等待其他玩家评价': '等待其他玩家评价',
     '您还没有创建房间': '您还没有创建房间',
     '开始挖矿': '开始挖矿',
     '退出房间': '退出房间',
     '复制链接': '复制链接',
     '发送邀请': '发送邀请',
     '提示': '提示',
     '请确认是否退出聊天室': '请确认是否退出聊天室',
     '请输入邀请链接': '请输入邀请链接',
     '加入聊天室': '加入聊天室',
     '在挖矿中': '在挖矿中',
     '定时进程': '定时进程',
     '完成挖矿': '完成挖矿',
     '停止聊天室': '停止聊天室',
     '在线玩家': '在线玩家',
     '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益': '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益',
     '社交聊天室': '社交聊天室',
     '创建聊天室': '创建聊天室',
     '邀请链接': '邀请链接',
     '有新的请求，请在请求弹窗中查看': '有新的请求，请在请求弹窗中查看',
     '有新的邀请，请在邀请弹窗中查看': '有新的邀请，请在邀请弹窗中查看',
     '房主已拒绝': '房主已拒绝',
     '用户拒绝加入': '用户拒绝加入',
     '挖矿收益': '挖矿收益',
     '我的评分': '我的评分',
     '奖励分配': '奖励分配',
     '评分': '评分',
     '社交评分': '社交评分',
     '提交': '提交',
     "暂无计划":"暂无计划",
     "活动报告":"活动报告",
     "分钟":"分钟",
     "每日":"每日",
     "每月":"每月",
     "获得":"获得",
     "总支出":"总支出",
     "点 击 关 闭":"点 击 关 闭",
     "朋友帮助请求":"朋友帮助请求",
     "发送帮助":"发送帮助",
     "推荐任务":"推荐任务",
     "描述":"描述",
     "接受":"接受",
     "待完成":"待完成",
     "特别活动":"特别活动",
     "特别":"特别",
     '此操作会将房间解散，并且不会退还钥匙': '此操作会将房间解散，并且不会退还钥匙',
     '运动收益': '运动收益',
     '社交收益': '社交收益',
     '累计时长(分钟)': '累计时长(分钟)',
     '实时运动': '实时运动',
     '领取': '领取',
     '交易成功后等级 + 1': '交易成功后等级 + 1',
     '信息更新中...': '信息更新中...',
     '连接钱包中...': '连接钱包中...',
     '解散房间': '解散房间',
     '未结算': '未结算',
     '已结算': '已结算',
     '日期选择': '日期选择',
     '未领取': '未领取',
     '已领取': '已领取',
     '已升级的nft数量': '已升级的nft数量',
     '请选择修复次数':'请选择修复次数',
     '暂无nft可领取':'暂无nft可领取',
     '暂无团队收益可领取':'暂无团队收益可领取',
     '团队收益':'团队收益',
     '当前修复分数': '当前修复分数',
     '请输入密码': '请输入密码',
     '设置密码': '设置密码',
     '密码': '密码',
     '再次确认': '再次确认',
     '请再次输入密码': '请再次输入密码',
     '两次输入的密码不一致': '两次输入的密码不一致',
     '未激活': '未激活',
     '已激活': '已激活',
     '成功': '成功',
     '失败': '失败',
     '请确认是否将此用户请离房间': '请确认是否将此用户请离房间',
     '收藏奖励': '收藏奖励',
     '教程': '教程',
     '请选择类型': '请选择类型',
     '评分(人气)': '评分(人气)',
     '日榜': '日榜',
     '月榜': '月榜',
     '总榜': '总榜',
     '升级进度': '升级进度',
     '暂无收益可领取': '暂无收益可领取',
     '价格需要大于5000': '价格需要大于5000',
     '团队NFT升级奖励': '团队NFT升级奖励',
     '名称': '名称',
     '钱包地址': '钱包地址',
     '设置昵称': '设置昵称',
     '昵称': '昵称',
     '请输入昵称': '请输入昵称',
     '昵称不能为空': '昵称不能为空',
     '请先选择头像': '请先选择头像',
     '设置个人信息': '设置个人信息',
     '数量': '数量',
     '选择钥匙数量': '选择钥匙数量',
     '房间人数需要大于2,才能正常退出': '房间人数需要大于2,才能正常退出',
     '价格需要大于50000': '价格需要大于50000',
     '是否确认绑定': '是否确认绑定?',
     '上级名称': '上级名称：',
     '上级邀请码': '上级邀请码：',
     '上级地址': '上级地址：',
     '请通过新的邀请链接进入': '请通过新的邀请链接进入',
     '群组/聊天': '群组/聊天',
     '此卡已是最高等级': 'NFT已是最高等级',
     '领取中': '领取中',
     '不可领取': '不可领取',
     '领取成功': '领取成功',
     '已卖出': '已卖出',
     '已升级': '已升级',
     '请先选择房间': '请先选择房间',
     '网络不好,请稍后再试': '网络不好,请稍后再试',
     '购买钥匙授权': '购买钥匙授权',
     '购买评分授权': '购买评分授权',
     '修复耐久授权': '修复耐久授权',
     '升级授权': '升级授权',
     '挂卖补偿奖励': '挂卖补偿奖励',
     '进入聊天室': '进入聊天室',
     '*请在1小时结束社交后进行评分': '*请在1小时结束社交后进行评分',
     '确认分享': '确认分享',
     '账号未激活，是否分享邀请链接?': '账号未激活，是否分享邀请链接?',
     '绑定上级中,链上交易需要时间,请耐心等待几分钟再去操作...': '绑定上级中,链上交易需要时间,请耐心等待几分钟再去操作...',
     '绑定成功': '绑定成功',
     '绑定失败': '绑定失败',
     '链上已经绑定上级,交易确认中,请稍后再试': '链上已经绑定上级,交易确认中,请稍后再试',
     '登录已过期,请重新登录': '登录已过期,请重新登录',
     '确认购买': '确认购买',
     '购买钥匙当次所需数量为:': '购买钥匙当次所需数量为:',
     '购买评分当次所需数量为:': '购买评分当次所需数量为:',
     '确认修复': '确认修复',
     '修复耐久当次所需数量为:': '修复耐久当次所需数量为:',
     '确认升级': '确认升级',
     '升级NFT当次所需数量为:': '升级NFT所需数量为:',
     '请填写E-mail': '请填写E-mail',
     '验证码': '验证码',
     '请填写短信验证码': '请填写短信验证码',
     '发送验证码': '发送验证码',
     'jommove上级邀请码': 'jommove上级邀请码',
     '如jommove已绑定上级，可不填': '如jommove已绑定上级，可不填',
     '请填写jommove上级邀请码': '请填写jommove上级邀请码',
     '绑定': '绑定',
     '请倒计时结束后再次发送': '请倒计时结束后再次发送',
     '请填写正确的邮箱': '请填写正确的邮箱',
     '请填写验证码': '请填写验证码',
     "技术升级":"技术升级",
}