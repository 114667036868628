/* 英文 */
export default {
     '一起社交赚钱': "Let's Social And Earn",
     '重连...': 'Reconnecting...',
     '收件箱': 'Mailbox',
     '聊天': 'Chat',
     '消息/聊天': 'Message/Chat',
     '消息 - 未读': 'Message - Unread',
     '没有更多了': 'No More',
     '暂无昵称': 'No Nickname Yet',
     '排行': 'Ranking',
     '在此输入...': 'Type Here...',
     '连接中...': 'Connecting...',
     '发送消息不能为空': 'Message Cannot Be Empty',
     '连接钱包': 'Connecting Wallet',
     '选择要连接的钱包': 'Select The Wallet To Connect',
     '钱包': 'Wallet',
     '好友': 'Friend',
     '朋友': 'Friend',
     '加载中...': 'Loading...',
     '添加好友': 'Add Friend',
     '请输入搜索关键词': 'Please Enter The Search Keyword',
     '推荐玩家': 'Recommended Players',
     '刷新': 'Refresh',
     '请求': 'Request',
     '好友请求': 'Friend Request',
     '积分': 'Points',
     'NFT收藏': 'NFT Collection',
     '探索距离': 'Distance',
     '步数': 'Steps',
     '心率': 'Heart BPM',
     '心率差': 'Heart BPM Difference',
     '卡路里': 'Calories',
     '时长': 'Duration',
     '耐用度': 'Durability',
     '开始运动': 'Start Exercise',
     '结束运动': 'End Exercise',
     '恢复耐久': 'Recover Durability',
     '日期预设': 'Preset Date',
     '月': 'Month',
     '日': 'Day',
     '户外跑步总记录': 'Outdoor Running Report',
     '查看活动报告': 'View Activity Report',
     '交易市场': 'Marketplace',
     '社交挖矿': 'Social',
     '选择年月': 'Select Year And Month',
     '修复中...': 'Repairing...',
     '修复成功': 'Repaired Successfully',
     '修复失败': 'Repair Failed',
     '市场': 'Market',
     '探索': 'Discover',
     '热门': 'Popular',
     '拍卖': 'Auction',
     '总共': 'Total',
     '暂无': 'None',
     '购买': 'Buy',
     '授权': 'Authorize',
     '余额不足': 'Insufficient Balance',
     '购买中...': 'Purchasing...',
     '购买成功': 'Purchased Successfully',
     '购买失败': 'Purchase Failed',
     '授权中...': 'Authorizing...',
     '授权成功': 'Authorization Succeeded',
     '授权失败': 'Authorization Failed',
     '我的NFT收藏': 'My NFT',
     'NFT角色信息': 'NFT Info',
     '取消挂卖': 'Cancel Listing',
     '挂卖': 'Sell',
     '升级中...': 'Level Up In Progress...',
     '升级': 'Level Up',
     '修复耐久性': 'Recover Durability',
     '信息': 'Message',
     '成长': 'Upgrade',
     '最高等级': 'Maximun Level',
     '请输入挂卖价格': 'Please Enter Selling Price',
     '确定': 'Confirm',
     '取消': 'Cancel',
     '价格': 'Price',
     '请输入价格': 'Please Enter A Price',
     '取消中...': 'Cancellation In Progress...',
     '取消成功': 'Cancellation Successfully',
     '取消失败': 'Cancellation Failed',
     '价格需要大于0': 'Price Needs To Be Greater Than 0',
     '挂卖中...': 'Listing In Progress...',
     '挂卖成功': 'Listed Successfully',
     '挂卖失败': 'List Failed',
     '这张卡片不需要修复': 'This NFT Does Not Need To Be Repaired',
     '修复成功,等待交易查询中': 'Repaired Successfully, Waiting For Transaction ',
     '升级失败': 'Upgrade Failed',
     '我的简历': 'My Profile',
     '地址': 'Address',
     '我的钱包余额': 'My Wallet Balance',
     '请输入口令': 'Please Enter Password',
     '当地语言': 'Local Language',
     '评分列表': 'Rating List',
     '购买评分': 'Purchase Rating',
     '密码不能为空': 'Password Can Not Be Empty',
     '复制成功': 'Copied',
     '不支持复制': 'Do Not Support Copy',
     '购买成功,等待交易查询中': 'Purchase Successfully, Waiting Transaction To Confirm',
     '主菜单': 'Main Menu',
     '首页': 'Main Page',
     '任务': 'Mission',
     '排行榜': 'Ranking',
     '开启运动模式': 'Move And Earn',
     '开启社交模式': 'Social And Earn',
     '聊天室': 'Social Room',
     '个人简介': 'My Profile',
     '报告': 'Report',
     '时间': 'Time',
     '前三名玩家': 'Top 3 Players',
     '暂无排名': 'No Ranking Yet',
     '玩家排行': 'Player Ranking ',
     '选择年月日': 'Select Year Month Day',
     '运动': 'Exercise',
     '社交': 'Social',
     '总分': 'Total Point',
     '持续': 'Continue',
     '玩家': 'Player',
     '等级': 'Level',
     '成本': 'Cost',
     '开始时间': 'Start Time',
     '至': 'Until',
     '结束时间': 'End Time',
     '收益': 'Income',
     '我的收益报告': 'My Profit Report',
     '奖励': 'Reward',
     '我的奖励报告': 'My Reward Report',
     '我的任务报告': 'My Mission Report',
     '我的NFT报告': 'My NFT Report',
     '开始时间要小于结束时间': 'Start Time Must Less Than End Time',
     '结束时间要大于开始时间': 'End Time Must Greater Than Start Time',
     '社交聊天': 'Social Chat',
     '聊天室名称': 'Social Room Name',
     '人数': 'Number Of People',
     '创建': 'Create',
     '房间名不能为空': 'Room Name Can Not Be Empty',
     '聊天室邀请': 'Invitation',
     '邀请': 'Invite',
     '暂无名称': 'No Name Yet',
     '距离': 'Distance',
     '准备': 'Ready',
     '邀请朋友': 'Invite Friend',
     '米': 'Meter',
     '暂无房间id': 'No Room ID',
     '我的聊天室': 'My Social Room',
     '购买钥匙': 'Purchase Key',
     '准备中': 'Preparing',
     '进行中': 'In Progress',
     '待评价': 'Pending For Rating',
     '待结算': 'Pending For Reward',
     '已结束': 'Finished',
     '暂未创建或加入聊天室': 'No Social Room Has Been Created Or Joined Yet',
     '推荐聊天室': 'Recommended Social Room',
     '头像': 'Profile Photo',
     '这个聊天室没有名称': 'This Social Room Has No Name',
     '申请': 'Apply',
     '已加入聊天室': 'Already Joined Social Room',
     '等待其他玩家评价': 'Waiting Players To Rate',
     '您还没有创建房间': 'You Have Not Created A Room',
     '开始挖矿': 'Start',
     '退出房间': 'Leave Room',
     '复制链接': 'Copy Invite Link',
     '发送邀请': 'Send Invitation',
     '提示': 'Reminder',
     '请确认是否退出聊天室': 'Please Confirm To Leave The Social Room',
     '请输入邀请链接': 'Please Enter The Invitation Link',
     '加入聊天室': 'Join Room',
     '在挖矿中': 'In Progress Of Social And Earn',
     '定时进程': 'Countdown',
     '完成挖矿': 'Complete',
     '停止聊天室': 'Stop Social Room',
     '在线玩家': 'Online Players',
     '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益': 'Please Confirm To Stop Social Room, This Operation Will Leave The Social Room And Will Not Get Reward.',
     '社交聊天室': 'Social Room',
     '创建聊天室': 'Create Room ',
     '邀请链接': 'Invitation Link',
     '有新的请求，请在请求弹窗中查看': 'There Is A New Request, Please Check In The Request Popup',
     '有新的邀请，请在邀请弹窗中查看': 'There Is New Invitation, Please Check In The Invitation Popup',
     '房主已拒绝': 'Room Owner Has Rejected Your Request',
     '用户拒绝加入': 'Player Is Rejected To Join',
     '挖矿收益': 'Social And Earn Reward',
     '我的评分': 'My Rating',
     '奖励分配': 'Reward Allocation',
     '评分': 'Rrating',
     '社交评分': 'Social Rating',
     '提交': 'Submit',
     "暂无计划":"No Plans Yet",
     "活动报告":"Activity Report",
     "分钟":"Minutes ",
     "每日":"Every Day",
     "每月":"Every Month",
     "获得":"Obtain",
     "总支出":"Total Spend",
     "点 击 关 闭":"Click To Close",
     "朋友帮助请求":"Friend Help Request",
     "发送帮助":"Send Help",
     "推荐任务":"Recommended Mission",
     "描述":"Description",
     "接受":"Accept",
     "待完成":"Pending",
     "特别活动":"Special Activity",
     "特别":"Special",
     '此操作会将房间解散，并且不会退还钥匙': 'This Action Will Disband The Room And The Key Will Not Be Returned',
     '运动收益': 'Sport Reward',
     '社交收益': 'Social Reward',
     '累计时长(分钟)': 'Cumulative Time (minutes)',
     '实时运动': 'Timer',
     '领取': 'Claim ',
     '交易成功后等级 + 1': 'Level + 1 after successful transaction',
     '信息更新中...': 'Information Being Update...',
     '连接钱包中...': 'Connecting Wallet...',
     '解散房间': 'Disband Room',
     '未结算': 'Unsettled',
     '已结算': 'Settled',
     '日期选择': 'Date Selection',
     '未领取': 'Not receive',
     '已领取': 'Claimed',
     '已升级的nft数量': 'Upgraded NFT quantity',
     '请选择修复次数':'Please select the number of repairs',
     '暂无nft可领取':'No NFT available for collection',
     '暂无团队收益可领取':'There are currently no team rewards to receive',
     '团队收益':'Team Rewards',
     '当前修复分数': 'Current Repair Durability',
     '请输入密码': 'Please Enter Password',
     '设置密码': 'Set password',
     '密码': 'Password',
     '再次确认': 'Confirm Password',
     '请再次输入密码': 'Please Enter Password Again',
     '两次输入的密码不一致': 'Password does not match',
     '未激活': 'Not Active',
     '已激活': 'Activated',
     '成功': 'Successful',
     '失败': 'Failed',
     '请确认是否将此用户请离房间': 'Please confirm if proceed to remove this user from the room',
     '收藏奖励': 'Collection Reward',
     '教程': 'Tutorial',
     '请选择类型': 'Please select a type',
     '评分(人气)': 'Rating',
     '日榜': 'Daily',
     '月榜': 'Monthly',
     '总榜': 'Total',
     '升级进度': 'upgrade progress',
     '暂无收益可领取': 'No income can be claimed temporarily',
     '价格需要大于5000': 'Price Needs To Be Greater Than 5000',
     '团队NFT升级奖励': 'NFT Upgrade Reward',
     '名称': 'Name',
     '钱包地址': 'Wallet Address',
     '设置昵称': 'Set Nickname',
     '昵称': 'Nickname',
     '请输入昵称': 'Please enter a nickname',
     '昵称不能为空': 'Nickname cannot be empty',
     '请先选择头像': 'Please select a avatar first',
     '设置个人信息': 'Set personal information',
     '数量': 'Number',
     '选择钥匙数量': 'Select the number of keys',
     '房间人数需要大于2,才能正常退出': 'The number of people in the room needs to be greater than 2 to exit normally',
     '价格需要大于50000': 'The price needs to be greater than 50000',
     '是否确认绑定': 'Do you confirm the binding?',
     '上级名称': 'Superior Name：',
     '上级邀请码': 'Superior Invitation Code：',
     '上级地址': 'Superior Address：',
     '请通过新的邀请链接进入': 'Please enter via a new invitation link',
     '群组/聊天': 'Group/Chat',
     '此卡已是最高等级': 'NFT Already at the Highest Level',
     '领取中': 'Claiming',
     '不可领取': 'Not claimable',
     '领取成功': 'Claim Successful',
     '已卖出': 'Sold out',
     '已升级': 'Upgraded',
     '请先选择房间': 'Please select a room first',
     '网络不好,请稍后再试': 'Poor network connection, please try again later',
     '购买钥匙授权': 'Purchase key authorization',
     '购买评分授权': 'Purchase rating authorization',
     '修复耐久授权': 'Repair durability authorization',
     '升级授权': 'Upgrade authorization',
     '挂卖补偿奖励': 'Listing compensation reward',
     '进入聊天室': 'Enter Social Room',
     '*请在1小时结束社交后进行评分': '*Please rate after 1 hour of social interaction',
     '确认分享': 'Confirm Sharing',
     '账号未激活，是否分享邀请链接?': 'Account not activated, do you want to share the invitation link?',
     '绑定上级中,链上交易需要时间,请耐心等待几分钟再去操作...': 'Binding the superior, the on-chain transaction takes time, please wait a few minutes before going to activate...',
     '绑定成功': 'Binding successful',
     '绑定失败': 'Binding failed',
     '链上已经绑定上级,交易确认中,请稍后再试': 'Superior already bound on-chain, transaction confirmation in progress, please try again late',
     '登录已过期,请重新登录': 'Login session expired, please log in again.',
     '确认购买': 'Confirm Purchase',
     '购买钥匙当次所需数量为:': 'Quantity needed to purchase keys:\n',
     '购买评分当次所需数量为:': 'Quantity needed to purchase ratings:\n',
     '确认修复': 'Confirm Repair',
     '修复耐久当次所需数量为:': 'Quantity needed to repair durability:\n',
     '确认升级': 'Confirm Upgrade',
     '升级NFT当次所需数量为:': 'The required quantity for upgrading NFT is\n',
     '请填写E-mail': 'Please fill in your E-mail',
     '验证码': 'Verification Code',
     '请填写短信验证码': 'Please fill in the SMS verification code',
     '发送验证码': 'Send Verification Code',
     'jommove上级邀请码': 'Jom Move Invitation Code',
     '如jommove已绑定上级，可不填': 'If already bound, no need to fill in',
     '请填写jommove上级邀请码': 'Please fill in the Jom Move invitation code',
     '绑定': 'Bind',
     '请倒计时结束后再次发送': 'Please resend after the countdown ends',
     '请填写正确的邮箱': 'Please enter a valid email',
     '请填写验证码': 'Please enter the verification code',
     "技术升级":" Upgrading",

}