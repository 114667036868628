import { post, get, uploadImg } from '../utils/reques'



export const login = params => post('/api/user/login', params)
export const userInfo = (params, Token) => get('/api/user/info', params, Token)
export const userBind = (params, Token) => post('/api/user/bind', params, Token)
export const updateInfo = params => post('/api/user/info', params, localStorage.getItem('Token'))//更新用户信息
export const mainConfig = params => get('/api/main/config', params)// 获取配置信息
export const userParent = params => post('/api/user/parent', params, localStorage.getItem('Token'))// 购买前测试绑定上级没
export const getBind = params => get('/api/user/bind', params, localStorage.getItem('Token'))// 判断能否操作绑定上级动作
export const getInfoBy = params => get('/api/user/infoBy', params, localStorage.getItem('Token'))// 根据UID或邀请码获取用户信息
export const getInfoByIds = params => get('/api/user/infoByIds', params, localStorage.getItem('Token'))// 根据uid批量查询

export const language = params => post('/api/user/lang', params, localStorage.getItem('Token'))// 设置语言
export const uploadApi = params => post('api/main/upload', params, localStorage.getItem('Token'))// 上传


//nft
export const getMyNftList = params => get('/api/nft/myNfts', params, localStorage.getItem('Token'))// 获取我的nft列表 
export const nftSwitch = params => get('/api/user/Switch', params, localStorage.getItem('Token'))// 首页切换nft
export const nftRepair = params => get('/api/user/Repair', params, localStorage.getItem('Token'))// 修复nft
export const nftUpgrade = params => post('/api/user/Upgrade', params, localStorage.getItem('Token'))// 升级nft
export const nftSportState = params => get('/api/user/NftState', params, localStorage.getItem('Token'))// nft运动状态 
export const nftBuy = params => post('/api/nft/buy', params, localStorage.getItem('Token'))// 购买nft(抢购并发控制)
export const nftOrderBefore = params => post('/api/nft/orderbefore', params, localStorage.getItem('Token'))// 挂卖或取消挂卖之前
export const nftOrder = params => post('/api/nft/order', params, localStorage.getItem('Token'))// 挂卖或取消挂卖成功后
export const nftCancel = params => post('/api/user/Cancel', params, localStorage.getItem('Token'))// 取消挂卖之前
export const nftHangingSale = params => post('/api/user/HangingSale', params, localStorage.getItem('Token'))// nft挂卖
export const nftOrderFail = params => post('/api/nft/orderFail', params, localStorage.getItem('Token'))// 挂卖失败或取消挂卖失败


//运动挖矿接口
export const getMotionStatistics = params => get('/api/sport/lineChart', params, localStorage.getItem('Token'))// 获取步数心率卡路里历史数据 (获取运动折线图数据)
export const getWatchInfo = params => get('/api/user/WatchInfo', params, localStorage.getItem('Token'))// 运动手表信息 (首页数据)
export const startSport = params => get('/api/user/StartMotion', params, localStorage.getItem('Token'))// 首页开始运动  
export const getPercentage = params => get('/api/user/Percentage', params, localStorage.getItem('Token'))// 步数心率卡路里 (环形图百分比) 
export const getDateList = params => get('/api/user/DateList', params, localStorage.getItem('Token'))// 查询日期预设信息(首页)
export const getDateInfo = params => get('/api/sport/sportPlan', params, localStorage.getItem('Token'))// 查询日期预设详情(根据日期获取运动计划)
export const sportEnd = params => get('/api/user/EndMarket', params, localStorage.getItem('Token'))// 结束运动
export const Sportdata = params => get('/api/sport/todaySport', params, localStorage.getItem('Token'))// 当次运动数据(获取上次运动数据)



//好友模块接口
export const getFriendsList = params => get('/api/im/friendList', params, localStorage.getItem('Token'))// 好友排行榜(我的好友列表)
export const getRecommend = params => get('/api/im/recommendUserList', params, localStorage.getItem('Token'))// 推荐好友列表
export const getFriendResList = params => get('/api/im/friendRequestList', params, localStorage.getItem('Token'))// 我的好友请求列表
export const handleRequest = params => post('/api/im/friendRefuse', params, localStorage.getItem('Token'))// 拒绝好友请求(同意走im)
export const addFriendsRequest = params => get('/api/user/AddFriendsRequest', params, localStorage.getItem('Token'))// 添加请求
export const FriendsnumApi = params => get('/api/user/Friendsnum', params, localStorage.getItem('Token'))// 信息数量统计


// 社交挖矿
export const AddChatApi = params => post('/api/user/AddChat', params, localStorage.getItem('Token'))// 创建房间
// export const AddRoomRequestApi = params => post('/api/room/AddRoomRequest', params, localStorage.getItem('Token'))// 邀请加入
// export const AddRoomApi = params => post('/api/room/AddRoom', params, localStorage.getItem('Token'))// 同意/拒绝加入房间
export const RecommendChatApi = params => get('/api/room/chatList', params, localStorage.getItem('Token'))// 推荐房间列表
export const OutApi = params => post('/api/user/Out', params, localStorage.getItem('Token'))// 退出房间
export const ChatInfoApi = params => get('/api/room/ChatInfo', params, localStorage.getItem('Token'))// 房间信息
export const ChatListApi = params => get('/api/room/applyList', params, localStorage.getItem('Token'))// (申请列表或邀请列表)
// export const InvitationApi = params => get('/api/room/Invitation', params, localStorage.getItem('Token'))// 邀请列表
export const StartMiningApi = params => post('/api/room/StartMining', params, localStorage.getItem('Token'))// 开始挖矿
export const ConfgScoreApi = params => get('/api/room/ConfgScore', params, localStorage.getItem('Token'))// 评分配置
export const partyEvaluateApi = params => post('/api/room/PartyEvaluate', params, localStorage.getItem('Token'))// 提交评分
export const ApplyApi = params => post('/api/room/apply', params, localStorage.getItem('Token'))// 成员申请加入房间 或 邀请好友按钮加入房间
export const EvaluateListApi = params => get('/api/room/EvaluateList', params, localStorage.getItem('Token'))// 获取待评分列表
export const AddUrlRoomApi = params => post('/api/room/addRoomByUrl', params, localStorage.getItem('Token'))// 邀请链接直接加入房间
export const StartScoreApi = params => post('/api/room/endMining', params, localStorage.getItem('Token'))// 开始评分(类似于结束挖矿)
export const ScoredListApi = params => get('/api/room/ScoredList', params, localStorage.getItem('Token'))// 被评分列表
export const ScoredInfoApi = params => get('/api/room/ScoredInfo', params, localStorage.getItem('Token'))// 被评分信息
export const ChatOperationApi = params => post('/api/room/applyVerify', params, localStorage.getItem('Token'))// 成员或房主审批申请加入房间信息(同意/拒绝)
export const BuyIntegralApi = params => get('/api/user/BuyIntegral', params, localStorage.getItem('Token'))// 购买评分
export const BuyKeyApi = params => get('/api/user/BuyKey', params, localStorage.getItem('Token'))// 购买钥匙
export const DelChatApi = params => post('/api/user/DelChat', params, localStorage.getItem('Token'))// 解散房间
export const isCanOutApi = params => get('/api/room/canOut', params, localStorage.getItem('Token'))// 判断成员是否允许退出当前房间


//排行榜
export const getRankingList = params => get('/api/user/ranking', params, localStorage.getItem('Token'))// 排行榜
export const getBannerList = params => get('/api/banner/list', params, localStorage.getItem('Token'))// 获取排行榜轮播图


//报告
export const getUpgradeList = params => get('/api/user/income', params, localStorage.getItem('Token'))// 报告
export const getIncomeReceiveNumber = params => get('/api/user/incomeReceiveNumber', params, localStorage.getItem('Token'))// 获取收益奖励nft升级次数可领取总数
export const incomeReceive = params => post('/api/user/incomeReceive', params, localStorage.getItem('Token'))// 领取奖励


//任务 
export const getTasknewList = params => get('/api/tasknew/index', params, localStorage.getItem('Token'))// 任务
export const TaskReceiveApi = params => post('/api/user/TaskReceive', params, localStorage.getItem('Token'))// 领取任务

/* 聊天 */
export const MsgListApi = params => get('/api/im/conversationList', params, localStorage.getItem('Token'))// 单聊会话列表
export const HistoricalDataApi = params => get('/api/user/HistoricalData', params, localStorage.getItem('Token'))// 聊天记录
export const sendMsgApi = params => post('/api/user/sendMsg', params, localStorage.getItem('Token'))// 发消息
export const getUnread = params => get('/api/im/Unread', params, localStorage.getItem('Token'))// 单聊会话未读数量

// 市场
export const MarketplaceApi = params => get('/api/nft/market', params, localStorage.getItem('Token'))// 市场列表 tab 默认0：探索 1：拍卖

//领取收益
export const sendApi = params => post('/api/user/Send', params, localStorage.getItem('Token'))// 领取收益(运动/社交)
export const moneyTotalApi = params => post('/api/user/MoneyTotal', params, localStorage.getItem('Token'))// 查询可领取收益数量
export const receiveApi = params => post('/api/user/receive', params, localStorage.getItem('Token'))// 领取团队收益


// 获取当前服务器时间 
export const systemTimeApi = params => get('/api/main/nowTime', params, localStorage.getItem('Token'))// 获取服务器当前时间


//meta2.0所需要的秘钥接口
export const getEncryCode = params => get('/api/main/code', params, localStorage.getItem('Token'))//获取加密随机串
export const setWatchPassword = params => post('/api/user/AppPassword', params, localStorage.getItem('Token'))//设置手表的口令


//nft升级新增接口 
export const getIsMarket = params => get('/api/user/IsMarket', params, localStorage.getItem('Token'))//可领取的nft(升级后)
export const receiveNft = params => post('/api/user/ReceiveNft', params, localStorage.getItem('Token'))//领取升级的nft


//团队NFT升级奖励
export const NFTUpgradeTeamAwardReceiveTotal = params => get('/api/receiveAward/NFTUpgradeTeamAwardReceiveTotal', params, localStorage.getItem('Token'))//可领取总数
export const NFTUpgradeTeamAwardReceive = params => post('/api/receiveAward/NFTUpgradeTeamAwardReceive', params, localStorage.getItem('Token'))//领取


//IM模块
export const getUserSign = params => get('/api/im/usersign', params, localStorage.getItem('Token'))//获取当前登录用户的腾讯IM的userSign


//登陆前判断用户是否有上级及展示上级信息
export const getUserHadParent = params => get('/api/user/hadParent', params, localStorage.getItem('Token')) //登陆前判断用户是否有上级及展示上级信息


// 下单
export const orderCreate = params => post('/api/order/create', params, localStorage.getItem('Token')) // 下单
export const coinByType = params => get('/api/order/coinByType', params, localStorage.getItem('Token')) // 获取下单币种配置
export const nftRelatedPriceApi = params => get('/api/nft/NftRelatedPrice', params, localStorage.getItem('Token')) // 获取 升级/购买钥匙/购买评分/修复耐久 等相关价格

// jommove模块
export const bindAppEmailApi = params => post('/api/user/bindAppEmail', params, localStorage.getItem('Token')) // jommoveEmail绑定
export const sendEmailApi = params => post('/mall_app/email/sendEmail', params, localStorage.getItem('Token')) // 发送邮件
